@import url("https://fonts.googleapis.com/css?family=Outfit");

:root,
[data-theme="light"] {
  --primary-bg: #dce5e3;
  --secondary-bg: #ffffff;
  --textfield-bg: #fafefe;
  --primary-txt: #1a1a1a;
  --nav-bg: #1a1a1a;
  --nav-txt: white;
  --box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
}

html[data-theme="dark"] {
  --primary-bg: #0d1116f8;
  --secondary-bg: #1e2833;
  --primary-txt: #ffffff;
  --nav-bg: #263240;
  --nav-txt: white;
  --box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
}

/* html[data-theme="dark"] {
  --primary-bg: #131920;
  --secondary-bg: #263240;
  --primary-txt: #ffffff;
  --nav-bg: #1e2833;
  --nav-txt: white;
} */

/* html[data-theme="dark"] {
  --primary-bg: #090909;
  --secondary-bg: #2c3a47;
  --primary-txt: #ffffff;
  --nav-bg: #383838;
  --nav-txt: white;
} */

* {
  font-family: "Outfit";
}

/* Sidebar Container */
.nav-main {
  z-index: 9999;
  position: fixed;
  width: 75px;
  padding-top: 16px;
  left: 0;
  height: 100vh; /* Ensure full height */
  overflow-x: hidden;
  background-color: var(--nav-bg);
  color: var(--nav-txt);
  transition: width 0.3s ease-in-out;
}

foreignObject {
  color: #1a1a1a;
}

/* Expanded Sidebar on Hover */
.nav-main:hover {
  width: 250px; /* Expanded width on hover */
}

/* Logo Container */
.logo {
  position: relative;
  display: block;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Common Logo Image Styles */
.nav-logo-img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease;
  max-height: 40px;
  width: auto;
}

/* Full Logo - Hidden by Default */
.nav-logo-img.full-logo {
  opacity: 0;
  transform: translateX(-50%) scale(0.95);
}

/* Small Logo - Visible by Default */
.nav-logo-img.small-logo {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

/* Hover States */
.nav-main:hover .nav-logo-img.full-logo {
  opacity: 1;
  transform: translateX(-50%) scale(1);
}

.nav-main:hover .nav-logo-img.small-logo {
  opacity: 0;
  transform: translateX(-50%) scale(1.05);
}
/* Navigation Items */
.nav-item-custom {
  display: flex;
  align-items: center;
  padding: 0.66rem 1rem;
  border-radius: 8px;
  margin-bottom: 0.25rem;
  transition: padding-left 0.3s ease-in-out;
}

.nav-main:hover .nav-item-custom {
  padding-left: 16px; /* Smooth alignment */
}

.nav-label {
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  white-space: nowrap; /* Prevent text wrapping */
  margin-left: 10px; /* Space between icon and label */
}

.nav-main:hover .nav-label {
  opacity: 1;
  transform: translateX(0);
}

.nav-item-custom.active {
  background-color: #53b8aa;
  color: white;
}

.nav-main .btn {
  color: white;
  display: flex;
  align-items: center;
}

/* Dropdown Buttons */
.nav-main .dropdown .btn {
  display: flex;
  align-items: center;
  padding-left: 0;
  transition: padding-left 0.3s ease-in-out;
}

.nav-main:hover .dropdown .btn {
  padding-left: 16px; /* Smooth alignment */
}

/* Dropdown Content */
.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.nav-main:hover .dropdown-content {
  max-height: 500px; /* Adjust as needed to accommodate content */
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.nav-main:hover .logo {
  justify-content: flex-start;
}

.progress-bar {
  background-color: #53b8aa;
}

.progress {
  height: 10px;
}

.material-icons,
.material-icons-outlined {
  font-size: 0.9em !important;
}

body {
  margin-left: 75px;
  background-color: var(--primary-bg);
  color: var(--primary-txt);
  overflow-x: hidden;
}

.banner {
  background-color: #53b8aa;
  color: white;
}

.form-control-custom {
  background-color: var(--primary-bg);
  color: var(--primary-txt);
  border: 1px dashed;
  font-style: italic;
  font-size: 1.5rem;
  padding: 0;
}

.form-control {
  background-color: var(--primary-bg);
  color: var(--primary-txt);
}

.form-control-custom:disabled {
  background-color: var(--primary-bg);
  color: var(--primary-txt);
  border: none;
  font-style: unset;
}

.secondary {
  background-color: var(--secondary-bg);
}

.banner.top {
  padding: 2em;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-item {
  font-size: 0.9em;
  color: var(--primary-txt);
}

.dropdown-menu {
  text-align: center;
}

.btn {
  background-color: transparent;
  color: var(--primary-txt);
  border-color: var(--primary-txt);
  letter-spacing: 1px;
}

.nav-btn {
  background-color: transparent;
  color: var(--primary-txt);
  letter-spacing: 1px;
}

.btn.active,
.nav-btn:hover,
.btn:hover {
  background-color: #53b8aa;
  color: white;
  border-color: transparent;
}

.logo {
  font-size: large;
  display: block;
  text-align: center;
  font-style: italic;
  margin-bottom: 2rem;
}

.center {
  margin: auto;
  padding: 2.5rem;
  font-style: italic;
  opacity: 50%;
}

.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  padding: 20px;
}

.text-btn {
  background: none;
  color: #007bff;
  border: none;
  padding: 4px;
  margin-top: 8px;
  cursor: pointer;
  font-size: 14px;
}

.cursor {
  cursor: pointer;
}

.text-btn:hover {
  background-color: transparent;
}

.loading {
  animation: opacity 1s linear infinite;
  opacity: 1;
}

@keyframes opacity {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}

.location-card {
  border: 1px solid inherit;
  border-radius: 30px;
}

.rect {
  transform-origin: center;
  transform: rotate(90deg);
  transform-box: fill-box;
}

.green-1 {
  fill: #1d5b33;
}

.green-2 {
  fill: #2d6d48;
}

.green-3 {
  fill: #60bf8b;
}

.green-4 {
  fill: #fafefe;
  opacity: 0.5;
}

.red-3 {
  fill: #d85555;
}

.red-2 {
  fill: #892929;
}

.red-1 {
  fill: #9b3434;
}

.red-4 {
  fill: #fafefe;
  opacity: 0.5;
}

.sticky-top,
.p-3.rounded.shadow,
.dropdown-menu {
  background-color: var(--primary-bg);
  color: var(--primary-txt);
}

.textfield-auth {
  background-color: var(--textfield-bg);
  color: #0d1116;
}

.between {
  display: flex;
  justify-content: space-between;
}

.input-group-text {
  background-color: inherit;
  border: 0;
}

.badge {
  font-size: small;
  padding: 1px 10px;
  border-radius: 10px;
  letter-spacing: 1px;
  font-weight: normal;
  background-color: #53b8aa;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
}

.elevated-card {
  border-radius: 15px;
  margin-top: 16px;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15);
  padding: 16px;
}

.elevated-card-2 {
  margin: 20px;
  padding: 35px;
  border-radius: 20px;
  background-color: var(--primary-bg);
  /* box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15); */
}

.elevated-card-3 {
  margin: 8.5px;
  padding: 35px;
  border-radius: 20px;
  background-color: var(--primary-bg);
  /* box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.15); */
}

.analytics-mini-card {
  border-radius: 15px;
  height: 125px;
  background-color: var(--primary-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.analytics-text {
  font-weight: 300;
  font-size: 40px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.queuesRed {
  color: #b22626;
}

.hours-title {
  min-width: 450px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 800;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__month-view__days__day--weekend:disabled {
  color: #808080 !important;
  opacity: 90% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.map-enter {
  opacity: 0;
  transform: scale(0.9);
}
.map-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.map-exit {
  opacity: 1;
  transform: scale(1);
}
.map-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.panel {
  background-color: var(--primary-bg);
  color: var(--primary-txt);
  border-radius: 20px;
}

.panel-2 {
  background-color: var(--secondary-bg);
  color: var(--primary-txt);
}

.MuiSlider-root {
  height: 15px !important;
}

.MuiSlider-markLabel {
  margin-top: 10px !important;
}

.inner-card {
  border-radius: 20px;
  margin: 10px;
}

.pulsing-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

.postponed {
  /* border-color: #d47500; */
  color: #d47500;
}
.postponed:hover {
  background-color: #d47500 !important;
}
.postponed-text {
  color: #d47500;
  font-style: italic;
}

.makeup {
  /* border-color: #ffc107; */
  color: #A020F0;
}
.makeup:hover {
  background-color: #A020F0 !important;
  color: white;
}
.makeup-text {
  color: #A020F0;
  font-style: italic;
}

/* Location.js Nav Menu */

a {
  color: inherit !important;
}

.nav-underline {
  letter-spacing: 2px;
}

.non-btn {
  border: none;
}

.non-btn:hover {
  background-color: inherit;
  color: inherit;
  cursor: inherit;
}

.MuiSlider-markLabel {
  color: var(--primary-txt) !important;
}

.graph {
  /* background-color: var(--primary-bg) !important; */
  color: var(--primary-txt) !important;
}

.locations-header,
.locations-header input,
.locations-header select {
  background-color: var(--secondary-bg);
  color: var(--primary-txt);
}

.concession-card {
  background-color: var(--secondary-bg);
  box-shadow: var(--box-shadow);
}

.retail-card {
  background-color: var(--secondary-bg);
  box-shadow: var(--box-shadow);
}

.section-card {
  color: var(--primary-txt);
  /* border: 1px solid var(--primary-txt); */
  /* background-color: var(--primary-bg); */
}

.concession-banner {
  background-color: var(--secondary-bg);
  color: var(--primary-txt);
}

.section-detail-card {
  border: 0.0px solid var(--primary-txt); /* 0 border width for now */
}

.dropdown-item.dropdown-item-custom:hover {
  background-color: #53b8aa !important;
  color: white !important;
}
